<template>
  <div>
    <b-card>
      <b-card-text>
        <strong> {{ $t("Welcome?") }} 🔒</strong>
      </b-card-text>
    </b-card>
    <section id="dashboard-analytics">
      <b-row class="match-height">
        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            v-if="data.notification_count"
            icon="TruckIcon"
            :statistic="data.notification_count"
            :statistic-title="$t('Waybills')"
          />
        </b-col>
        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            icon="ActivityIcon"
            color="warning"
            :statistic="data.open_waybills_count"
            :statistic-title="$t('Open Waybills')"
          />
        </b-col>

        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            icon="LayersIcon"
            color="primary"
            :statistic="data.transaction_count"
            :statistic-title="$t('Transactions')"
          />
        </b-col>
        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            icon="LockIcon"
            color="success"
            :statistic="data.closed_transaction_count"
            :statistic-title="$t('Closed Transactions')"
          />
        </b-col>
        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            icon="DollarSignIcon"
            color="info"
            :statistic="data.balance"
            :statistic-title="$t('Balance')"
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from "bootstrap-vue";
import StatisticCardVertical from "../@core/components/statistics-cards/StatisticCardVertical.vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardVertical,
  },
  data() {
    return {
      data: {
        arrived_notification_count: 0,
        balance: 0,
        balance_type: 0,
        closed_transaction_count: 0,
        notification_count: 0,
        open_waybills_count: 0,
        transaction_count: 0,
      },
    };
  },

  created() {
    this.$store.commit("START_LOADING");
    this.$http.get("/crm/home").then((response) => {
      this.data = response.data;
      this.$store.commit("STOP_LOADING");
    });
  },
};
</script>
